import React from "react";
import ReactDOM from "react-dom";
import AudioPlayer from "./AudioPlayer";
import AudioLichphat from "./AudioLichphat";
import tracks from "./tracks";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path="/:id" element={<AudioPlayer tracks={tracks} />} />
        <Route path="/lichphat/:id" element={<AudioLichphat tracks={tracks} />} />
      </Routes>
    </Router>
    {/* <AudioPlayer tracks={tracks} /> */}
  </React.StrictMode>,
  rootElement
);
