import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
// import ReactAudioPlayer from 'react-audio-player';
import ReactHlsPlayer from 'react-hls-player';
import { useParams } from 'react-router-dom';
import imgSrc from "./assets/artwork.jpg";
import AudioControls from "./AudioControls";
import Backdrop from "./Backdrop";
import "./styles.css";
import { Socket } from "./socket";

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
 */

// http://localhost:4000
//https://server1.gtechdn.vn
const AudioLichphat = (props) => {
  const server = 'https://server1.gtechdn.vn'
  const { tracks } = props
  const { id } = useParams();



  // State
  const [trackIndex] = useState(0);
  // const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [m3u8, setm3u8] = useState(true);
  const [fileMp3, setfileMp3] = useState(false);
  const [nguoiphat, setNguoiphat] = useState('');
  const [noidung, setnoidung] = useState('');
  const [noidungcho, setnoidungcho] = useState('');
  const [bantin, setbantin] = useState('');
  const [src, setSrc] = useState('');
  const [ketthuc, setKetthuc] = useState();

  // Destructure for conciseness
  const { color } = tracks[trackIndex];

  // Refs
  const playerRef = React.useRef();

  const audioRef = useRef(new Audio());
  // const intervalRef = useRef();

  const [hours, sethours] = React.useState(null);
  const [minutes, setminutes] = React.useState(null);
  const [seconds, setseconds] = React.useState(null);
  const [eventTime, seteventTime] = React.useState(null);
  const [eventTimeCho, seteventTimeCho] = React.useState(null);
  const [timereload, settimereload] = React.useState(null);
  const [chophat, setchophat] = React.useState(false);
  const [chutoingay, setchutoingay] = React.useState(null);
  const [thoigiancho, setthoigiancho] = React.useState(null);
  const [chuabatdau, setchuabatdau] = React.useState(null);
  const [thoigianketthuc, setthoigianketthuc] = React.useState(null);
  const hienthithoiluong = (time) => {

    return time < 10 ? `0${time}` : `${time}`
  }
  if (fileMp3) {
    audioRef.current.onpause = function () {
      if (ketthuc === true) {
        setSrc('')
        setbantin('')
        setNguoiphat('')
        seteventTime(null)
        audioRef.current.pause()
      } else {
        const timkiemM3u8 = src.search('m3u8')
        const timkiemNoidung = noidungcho.search('mp3')
        if (timkiemM3u8 !== -1) {

          playerRef.current.play();
          setm3u8(true)
        } else {
          audioRef.current.src = src
          audioRef.current.play();
          setm3u8(false)
        }
        if (timkiemNoidung !== -1) {
          setfileMp3(true)
        } else {
          setfileMp3(false)
        }
        setnoidung(noidungcho)
        seteventTime(eventTimeCho)

        audioRef.current.play()

      }

      // window.location.reload();

    }
  } else {
    if (timereload === 0) {
      setTimeout(function () {
        // window.location.reload();
      }, 2000)

    }
  }

  const setplay = (value) => {
    if (value === true) {
      if (m3u8) {
        playerRef.current.play();
      } else {
        audioRef.current.play();
      }
    } else {
      if (m3u8) {
        playerRef.current.pause();
      } else {
        audioRef.current.pause();
      }
    }

  }
  useEffect(() => {
    Socket.on('GUI-NGHE-TRUC-TIEP-KET-THUC', (message) => {
      if (String(message.idlichphat) === String(id)) {
        const time = new Date()
        const thoigianhientai = new Date().getTime()
        const ketthuc = new Date(thoigianketthuc)
        const timebatdau = new Date(time.getFullYear(), time.getMonth(), time.getDate(), ketthuc.getHours(), ketthuc.getMinutes(), ketthuc.getSeconds())
        const thoigianDaqua = Math.round((timebatdau.getTime() - thoigianhientai) / 1000)

        if (thoigianDaqua > 5) {
          setSrc('')
          setbantin('')
          setNguoiphat('')
          seteventTime(null)
          audioRef.current.pause()
          if (m3u8) {
            playerRef.current.pause();
          }
        } else {
          setKetthuc(true)
        }
        if (!fileMp3) {
          setSrc('')
          setbantin('')
          setNguoiphat('')
          seteventTime(null)
          audioRef.current.pause()
          if (m3u8) {
            playerRef.current.pause();
          }
        }
      }
    });
    return () => {
      Socket.off('GUI-NGHE-TRUC-TIEP-KET-THUC');
    };
  }, [fileMp3, id, m3u8, thoigianketthuc]);

  useEffect(() => {
    Socket.on('GUI-NGHE-TRUC-TIEP', (message) => {
      if (String(message.idlichphat) === String(id)) {
        setthoigianketthuc(new Date(message.nguonphat.liveThoigian.ketthuc))
        setnoidung(message.file)
        if (!fileMp3) {
          // setIsPlaying(false)
          setchophat(false)
          setnoidung(message.file)
          setNguoiphat(message.nguonphat.nguoitao.hoten)
          setbantin(message.nguonphat.tenchuongtrinh)
          const timkiemM3u8 = message.url.search('m3u8')
          const timkiemNoidung = message.file.search('mp3')
          if (timkiemM3u8 !== -1) {
            audioRef.current.pause();
            setSrc(message.url)
            setm3u8(true)
            playerRef.current.play();

          } else {
            playerRef.current.pause();
            audioRef.current.src = message.url
            audioRef.current.play()

            setm3u8(false)
          }
          if (timkiemNoidung !== -1) {
            setfileMp3(true)
          } else {
            setfileMp3(false)
          }
          seteventTime(moment(`${new Date(message.nguonphat.liveThoigian.ketthuc).getHours()}:${new Date(message.nguonphat.liveThoigian.ketthuc).getMinutes()}:${new Date(message.nguonphat.liveThoigian.ketthuc).getSeconds()}`, 'HH:mm:ss').unix())
        }
        setSrc(message.url)
        setnoidungcho(message.file)
        seteventTimeCho(moment(`${new Date(message.nguonphat.liveThoigian.ketthuc).getHours()}:${new Date(message.nguonphat.liveThoigian.ketthuc).getMinutes()}:${new Date(message.nguonphat.liveThoigian.ketthuc).getSeconds()}`, 'HH:mm:ss').unix())
      } else {

      }


    });
    return () => {
      Socket.off('GUI-NGHE-TRUC-TIEP');
    };
  }, [fileMp3, id, src]);


  useEffect(() => {
    if (m3u8) {
      playerRef.current.onplay = function () {
        setIsPlaying(true)
      }
      playerRef.current.onpause = function () {
        setIsPlaying(false)

      }
    }

    audioRef.current.onplay = function () {
      setIsPlaying(true)

    }
    audioRef.current.onpause = function () {
      setIsPlaying(false)

    }
    const interval = setInterval(() => {

      settimereload(moment.duration((eventTime - moment().unix()) * 1000, 'milliseconds')._milliseconds)
      sethours(hienthithoiluong(moment.duration(moment.duration((eventTime - moment().unix()) * 1000, 'milliseconds')).hours()))
      setminutes(hienthithoiluong(moment.duration(moment.duration((eventTime - moment().unix()) * 1000, 'milliseconds')).minutes()))
      setseconds(hienthithoiluong(moment.duration(moment.duration((eventTime - moment().unix()) * 1000, 'milliseconds')).seconds()))
    }, 1000);
    return () => clearInterval(interval);
  }, [eventTime, audioRef, m3u8, playerRef]);

  useEffect(() => {
    (async () => {
      await fetch(`${server}/v1/client/nghetructiep/lichphat${id}`)
        .then(res => res.json())
        .then((data) => {
          if (data.message === 'chua bat dau') {
            setchuabatdau(true)
          }
          if (data.message === 'sap phat!') {
            const ngayhientai = new Date().getDate()
            if (ngayhientai < new Date(data.data.batdau).getDate()) {
              setchutoingay(true)
              setthoigiancho(new Date(data.data.batdau))
            }
            // audioRef.current = new Audio('http://media.kythuatvov.vn:7001/;stream/')
            // setSrc('http://media.kythuatvov.vn:7001/;stream/')
            setchophat(true)
            audioRef.current.play();
            playerRef.current.play();
            setbantin(data.data.tenchuongtrinh)
            setNguoiphat(data.data.nguoiphat)

            seteventTime(moment(`${new Date(data.data.batdau).getHours()}:${new Date(data.data.batdau).getHours()}:${new Date(data.data.batdau).getMinutes()}:${new Date(data.data.batdau).getSeconds()}`, 'HH:mm:ss').unix())
          }
          setfileMp3(data.data.filemp3)
          setm3u8(data.data.m3u8)
          setSrc(data.data.url)
          setNguoiphat(data.data.nguoiphat)
          setnoidung(data.data.noidung)
          setbantin(data.data.tenchuongtrinh)

          audioRef.current = new Audio(data.data.url)
          audioRef.current.src = data.data.url
          setthoigianketthuc(new Date(data.data.ketthuc))
          seteventTime(moment(`${new Date(data.data.batdau).getHours()}:${new Date(data.data.batdau).getMinutes()}:${new Date(data.data.batdau).getSeconds()}`, ' HH:mm:ss').unix())
        })
        .catch(console.log)


    })();
  }, [id]);

  useEffect(() => {
    // if (isPlaying) {
    //   if (m3u8) {
    //     playerRef.current.play();
    //   } else {
    //     audioRef.current.play();
    //   }
    //   // startTimer();
    // } else {
    //   if (m3u8) {
    //     playerRef.current.pause();
    //   } else {
    //     audioRef.current.pause();
    //   }
    // }


  }, [isPlaying, m3u8]);



  return (
    <div className="audio-player">
      <div className="track-info">
        <img
          className="artwork"
          src={imgSrc}
          alt="anh"
        />
        {src !== '' ?
          <h2 className="title">{noidung}</h2> :
          chuabatdau ?
            <h2 className="title">Bản Tin Chưa Bắt Đầu</h2> :
            <h2 className="title">Bản Tin Đã Kết Thúc</h2>
        }
        <h3 className="bantin">{bantin}</h3>
        <h3 className="artist">{nguoiphat}</h3>

        {chophat ?
          <div>
            {chutoingay === true ?
              <h2 className="title">Bản Tin Bắt Đầu Lúc</h2> :
              <h2 className="title">Bắt Đầu Sau</h2>
            }
            {chutoingay === true ?
              <h3 className="artist"> {moment(thoigiancho).format('DD-MM-YYYY HH:mm:ss')}</h3> :
              <h3 className="artist"> {`${hours && timereload > 0 ? hours : '00'}:${minutes && timereload > 0 ? minutes : '00'}:${seconds && timereload > 0 ? seconds : '00'}`}</h3>
            }


          </div> : ''

        }
        {!chophat ?
          <AudioControls
            isPlaying={isPlaying}
            onPlayPauseClick={setplay}
          /> : ''
        }

        {/* {m3u8 ?
          <ReactHlsPlayer
            //autoPlay={true}
            playerRef={playerRef}
            src={src}
          /> : ''
        } */}
        <ReactHlsPlayer
          //autoPlay={true}
          playerRef={playerRef}
          src={src}
        />
        <div>
          <h5 className="chucuoi">© COPYRIGHT 2021 - GTECH.DN CO., LTD</h5>
        </div>


      </div>
      <Backdrop
        trackIndex={trackIndex}
        activeColor={color}
        isPlaying={isPlaying}
      />
    </div>
  );
};

export default AudioLichphat;
